<template>
    <div v-show="showPage" class="fake-auth-page">

        <div id="headerBar">
            <img @click="goBack" src="@/assets/img/back_white.png" id="picto-back" />
            <div id="auth-header-title">
                {{$t('fakeAuthHeaderTitle')}}
            </div>
        </div>

        <div class="authentication">
            <div class="auth-header">
                <div class="auth-header-logos">
                    <img src="@/assets/img/societe_generale.png">
                    <img id="verified" src="@/assets/img/verified_visa.png">
                </div>
                <h5>{{$t('fakeAuthHeaderAuth')}}</h5>
                <p class="authenticate-text">{{$t('fakeAuthHeaderText')}} <span v-if="$route.query.enroll">{{$t('fakeAuthHeaderEnrollText')}}</span>.</p>
                <p id="useCode" @click="useCode">{{$t('fakeAuthBodyGenerateCode')}}</p>
                <table>
                    <tr>
                        <td>{{$t('fakeAuthBodyMerchant')}}</td>
                        <td class="table-value">Worldline</td>
                    </tr>
                    <tr>
                        <td>{{$t('fakeAuthBodyDate')}}</td>
                        <td class="table-value">{{ $d(new Date(), 'long') }}</td>

                    </tr>
                    <tr>
                        <td>{{$t('fakeAuthBodyCardNumber')}}</td>
                        <td class="table-value">4971 XXXX XXXX XX43</td>
                    </tr>
                </table>
                <p class="help-text">{{$t('fakeAuthBodyHelpText')}}</p>

                <b-form @submit="codeSubmit" id="secureCodeForm" v-if="!useFingerPrint">
                    <b-form-input v-model="codeInput" placeholder="Code"></b-form-input>
                    <b-button type="submit" variant="primary" id="secureCodeSubmit">Submit</b-button>
                </b-form>

            </div>
            <div class="fake-auth">
                <div class="auth-body">
                    <div v-show="showScan" class="loading" ref="loading"></div>
                    <div v-show="success" class="loading" ref="success"></div>
                    <span v-show="success"><strong>{{$t('fakeAuthSuccessText')}}</strong></span>
                    <span v-show="showScan"><strong>{{$t('fakeAuthInProgressText')}}</strong></span>
                </div>
                <div v-touch:start="pressed" v-touch:end="released" v-if="useFingerPrint" class="btn-circle">
                    <div class="picto-img picto-fingerprint"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import lottie from 'lottie-web';
    var loading = require('@/assets/img/loading.json');
    var check = require('@/assets/img/check.json');

    export default {
        name: 'FakeAuthPage',
        data() {
            return {
                pressTimer: null,
                showScan: false,
                success: false,
                codeInput: "",
                useFingerPrint: true,
                showPage: false
            }
        },
        mounted() {
            this.$parent.loader.showLoader = true;
            this.$parent.loader.content = this.$t("authenticationLoadingText");
            setTimeout(()=>{
                this.$parent.loader.showLoader = false;
                this.$parent.loader.content = this.$t("loading");
                this.showPage = true;
            }, 4000)
            this.initAnimation(loading, this.$refs.loading, { name: "loading", loop: true });
            this.initAnimation(check, this.$refs.success, { name: "success", loop: false });
        },
        methods: {
            released() {
                this.showScan = false;
                clearTimeout(this.pressTimer);
            },
            pressed() {
                this.showScan = !this.showScan;
                this.success = false;
                this.pressTimer = setTimeout(() => {
                    lottie.goToAndStop(0, true, 'success');
                    lottie.play('success');
                    this.showScan = false;
                    this.success = true;
                    setTimeout(() => {
                        window.close();
                    }, 1500);
                }, 1000);

            },
            initAnimation(animation, ref, options = {}) {
                var anim = lottie.loadAnimation({
                    container: ref, // the dom element that will contain the animation
                    renderer: 'svg',
                    name: options.name,
                    loop: options.loop != false,
                    autoplay: true,
                    animationData: animation // the path to the animation json
                });
                return anim;
            },
            useCode(){
                this.useFingerPrint = false;
            },
            codeSubmit(evt){
                evt.preventDefault();
                this.pressed();
            },
            goBack(){
                localStorage.setItem("DKC_3DS_back", true);
                window.close();
            }
        }
    }
</script>